<template>
  <div class="login-right-normal">
    <img src="../../../../assets/pc/images/login/loginLogo.png" alt="" />
    <!--    右侧头部区域-->
    <div class="login-right-top">
      <span>欢迎登录</span>
      <!-- <router-link to="loginbywechat"><div>扫码登录更快捷</div></router-link> -->
    </div>
    <div class="tabs">
      <div
        v-for="(item, index) in tabs"
        :key="index"
        :class="item.active ? 'active' : ''"
        :style="index == 0 ? 'margin-right:24px' : ''"
        @click="handleToggle(item, index)"
      >
        {{ item.name }}
      </div>
    </div>
    <!--    右侧表单区域-->
    <div class="login-form">
      <el-form
        :model="normalLoginForm"
        :rules="loginFormRules"
        ref="loginFormRef"
      >
        <el-form-item prop="username">
          <div>手机号/用户名</div>
          <el-input
            v-model="normalLoginForm.username"
            placeholder="请输入手机号码或用户名"
            maxlength="11"
          >
          </el-input>
        </el-form-item>
        <el-form-item prop="password">
          <div>登录密码</div>
          <el-input
            v-model="normalLoginForm.password"
            placeholder="请输入登录密码"
            type="password"
            maxlength="20"
          ></el-input>
        </el-form-item>
        <!-- <el-form-item>
          <el-input v-model="normalLoginForm.code" placeholder="请输入图形验证码">
            <template slot="append">
              <img
                class="verifyCode"
                :src="imgUrl"
                @click="changeCode()"
                style="width: 100px;"
                ref="imgUrl"
                >
            </template>
          </el-input>
        </el-form-item> -->
        <el-form-item>
          <el-button type="primary" @click="loginSubmit">立即登录</el-button>
          <el-checkbox v-model="isRemember" style="margin:0px 0px 25px 0px;">记住密码</el-checkbox>
        </el-form-item>
        <el-form-item id="checkbox-form">
          <router-link to="forgetpaw"
            ><span class="forget">忘记密码？</span></router-link
          >
          <!-- <el-checkbox v-model="normalLoginForm.keepAlive">保持登录</el-checkbox> -->
          <router-link to="register"
            ><span class="register">免费注册></span></router-link
          >
        </el-form-item>
      </el-form>
    </div>
    <!--    右侧表单尾部区域-->
    <div class="login-right-footer">
      <div class="left">
        <span>微信扫码登录</span>
        <!-- <router-link to="loginbyverification"><span class="verification-code"></span></router-link> -->
        <router-link to="loginbywechat"
          ><span class="wechat-code"></span
        ></router-link>
      </div>
      <!-- <div class="right">
        <router-link to="forgetpaw">忘记密码？</router-link>
      </div> -->
    </div>
  </div>
</template>

<script>
import { request } from "@/network";
import Cookies from "js-cookie";
import { encrypt, decrypt } from '@/utils/jsencrypt';
import { getnoreadmessage } from "@/assets/public/utils/token";
import { accountInformation } from '@/api/simple_matching/index';
export default {
  name: "loginRightNormal",
  components: {},
  data() {
    return {
      normalLoginForm: {
        username: "",
        password: "",
        // code: '',
        codeStr: "",
        // keepAlive: false
      },
      loginFormRules: {
        // username: [
        //   {
        //     required: true,
        //     pattern: "^[1][3,4,5,6,7,8,9][0-9]{9}$",
        //     message: "请输入正确的11位手机号码",
        //     trigger: "blur",
        //   },
        // ],
        password: [{ required: true, message: "请输入密码", trigger: "blur" }],
      },
      imgUrl: "",
      key: "",
      tabs: [
        { name: "验证码登录", active: false, path: "loginbyverification" },
        { name: "密码登录", active: true, path: "normal" },
      ],
      isKeyDown: false,
      isRemember: false
    };
  },
  mounted() {
    this.getVerifyCode();
    this.onSubmitKeyDown();
    this.normalLoginForm.username = this.$route.query.phone ? this.$route.query.phone : '';
    this.getCookie();
  },
  watch: {
    // 'normalLoginForm.code': function (newVal, old) {
    //   if (newVal !== '') {
    //     this.isKeyDown = true;
    //   }
    // }
  },
  methods: {
    loginSubmit() {
      this.$refs.loginFormRef.validate(async (valid) => {
        if (valid) {
          if (this.isRemember) {
            Cookies.set("username", this.normalLoginForm.username, { expires: 30 });
            Cookies.set("password", encrypt(this.normalLoginForm.password), { expires: 30 });
            Cookies.set('isRemember', this.isRemember, { expires: 30 });
          } else {
            Cookies.remove("username");
            Cookies.remove("password");
            Cookies.remove('isRemember');
          }
          const { data: res } = await request({
            method: "POST",
            url: "/pcp/login",
            data: {
              username: this.normalLoginForm.username,
              password: this.normalLoginForm.password,
              keepAlive: 1,
              // code: this.normalLoginForm.code,
              // key: this.key,
            },
          });
          if (res.code === 200) {
            this.$message.success("登录成功");
            this.$store.commit("login/setUser", [res.data, true]);
            this.$router.push("/index");
            this.getLoginInfo();
          } else {
            // this.normalLoginForm.password = ''
            // this.getVerifyCode();
            this.$message.error(res.message);
          }
        }
      });
    },
    getCookie() {
      const username = Cookies.get("username");
      const password = Cookies.get("password");
      const isRemember = Cookies.get('isRemember');
      this.normalLoginForm = {
        username: username === undefined ? this.normalLoginForm.username : username,
        password: password === undefined ? this.normalLoginForm.password : decrypt(password),
      };
      this.isRemember = isRemember === undefined ? false : Boolean(isRemember);
    },
    getVerifyCode() {
      request({
        method: "GET",
        url: "/pcp/yzm/getVerifyCode",
      }).then((res) => {
        if (res.data.code === 200) {
          this.imgUrl = "data:image/gif;base64," + res.data.data.img;
          this.key = res.data.data.key;
        }
      });
    },
    changeCode() {
      this.getVerifyCode();
    },
    async getLoginInfo() {
      const { data: res } = await request({
        method: "POST",
        url: "/pcp/getLoginInfo",
      });
      if (res.code !== 200) return this.$message.error("获取用户信息失败");
      getnoreadmessage(request, res.data.id).then((resMessage) => {
        localStorage.setItem("notRead", resMessage);
        this.$store.commit("personal/setImgUrl", res.data.avatar);
        this.$store.commit("personal/setNotRead", resMessage);
      });
      if(!res.data.mobile) this.$router.push("/login/bindPhone");
    },
    handleToggle(item, index) {
      this.tabs.forEach((v, i) => {
        i === index ? (v.active = true) : (v.active = false);
      });
      this.$router.push({
        path: item.path,
        query: {
          phone: this.normalLoginForm.username
        }
      });
    },
    // 回车
    onSubmitKeyDown() {
      document.onkeydown = (e) => {
        // if (this.normalLoginForm.code !== '' && this.isKeyDown && this.normalLoginForm.code.length >= 4) {
        //   e = window.event || e;
        //   if (this.$route.path === '/login/normal' && (e.code === 'Enter' || e.code === 'enter')) {
        //     this.loginSubmit();
        //   }
        // }
        if (this.isKeyDown) {
          e = window.event || e;
          if (
            this.$route.path === "/login/normal" &&
            (e.code === "Enter" || e.code === "enter")
          ) {
            this.loginSubmit();
          }
        }
      };
    },
  },
};
</script>

<style scoped lang="less">
/*总体样式*/
.login-right-normal {
  // height: 550px;
  width: 320px;
  margin-left: 139px;
  padding: 40px 0;
}
/*右侧头部样式*/
.login-right-top {
  display: flex;

  padding-bottom: 15px;
  justify-content: center;
  margin-top: 35px;
  //  border-bottom: 2px solid #000000;
}
.login-right-top span {
  font-size: 24px;
  font-family: Microsoft YaHei;
  font-weight: 600;
  color: #000000;
}
.login-right-top div {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 140px;
  height: 28px;
  background: #dfe6ee;
  border-radius: 14px;
  font-size: 14px;
  color: #156ED0;
  cursor: pointer;
}
.login-right-top div:before {
  content: "";
  display: inline-block;
  margin-right: 4px;
  width: 16px;
  height: 16px;
  background: url("~@/assets/pc/images/login/login-code.png");
}
/*右侧表单样式*/
.login-form {
  margin-top: 35px;
  padding-bottom: 30px;
  border-bottom: 1px solid #e5e5e5;
}
.el-input {
  width: 100%;
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #000000;
}
.el-form-item:nth-child(n-1) {
  margin-bottom: 20px;
}
.el-form-item:last-child {
  margin-bottom: 0;
}
.login-form /deep/ .el-input > input {
  height: 50px;
  padding-left: 12px;
  font-size: 16px;
  color: #8d8d8d;
}
.el-button {
  width: 100%;
  font-size: 16px;
  font-weight: bold;
  color: #ffffff;
  background-color: #156ED0;
}
#checkbox-form {
  position: relative;
}
#checkbox-form .el-checkbox {
  width: 100px;
}
#checkbox-form .register {
  position: absolute;
  left: 0;
  top: 50%;
  color: #156ED0;
  cursor: pointer;
}
#checkbox-form .forget {
  position: absolute;
  right: 0;
  top: 50%;
  color: #8d8d8d;
  cursor: pointer;
}
/* checkbox选中后颜色不变*/
#checkbox-form /deep/ .el-checkbox__label {
  color: #000000;
  padding-left: 6px;
}
/*消除checkbox高度*/
#checkbox-form /deep/ .el-form-item__content {
  line-height: 0;
}
/*右侧表单尾部区域 */
.login-right-footer {
  display: flex;
  justify-content: center;
  align-items: center;
  // margin-top: 15px;
  // height: 24px;
  width: 100%;
}
.login-right-footer .left {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #000000;
}
.login-right-footer .left > span {
  margin: 14px 0;
}
.login-right-footer .left .verification-code {
  display: block;
  margin-left: 12px;
  width: 24px;
  height: 24px;
  background: url("~@/assets/pc/images/login/login-verification-code.png");
  cursor: pointer;
}
.login-right-footer .left .wechat-code {
  display: block;
  margin-left: 10px;
  width: 24px;
  height: 24px;
  background: url("~@/assets/pc/images/login/login-wechat-code.png");
  cursor: pointer;
}
.login-right-footer .right a {
  font-size: 14px;
  color: #8d8d8d;
  cursor: pointer;
}
/deep/.el-checkbox__input.is-checked .el-checkbox__inner {
  background-color: #156ED0;
  border-color: #156ED0;
}
.tabs {
  display: flex;
  justify-content: center;
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #000000;
  cursor: pointer;
  margin-top: 15px;
}
.active {
  font-weight: bold;
  border-bottom: 3px solid #156ED0;
  padding-bottom: 12px;
  color: #156ED0;
}
/deep/ .el-form-item__content {
  line-height: 20px;
}
</style>
